<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <molecule-list-table></molecule-list-table>
    </v-col>
  </v-row>
</template>

<script>
import moleculeListTable from '@/components/tables/moleculeListTable.vue'

export default {
  components: {
    moleculeListTable,
  },
  setup() {
    return {}
  },
}
</script>
