<template>
  <v-card>
    <v-card-title>
      <p color="primary">
        Molecule List
      </p>
    </v-card-title>
    <v-data-table
      :loading="loading"
      :headers="moleculePerMonthTableHeaders"
      :items="moleculePerMonthTableData"
      show-select
      item-key="group_series_1-group"
      class="table-rounded"
      sort-by="value"
      sort-desc
    >
      <template
        v-for="header in moleculePerMonthTableHeaders.filter(header => header.hasOwnProperty('formatter'))"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        {{ header.formatter(value) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { moleculePerMonthTableHeaders } from '@/assets/headers/index'
import { apiService } from '@/api/index'
import { useFilterStore } from '@/stores/FilterStore'

export default {
  name: 'MoleculePerMonthTable',
  setup() {
    const filterStore = useFilterStore()

    return { filterStore }
  },
  data() {
    return {
      moleculePerMonthTableHeaders,
      moleculePerMonthTableData: [],
      loading: false,
    }
  },
  destroyed() {
    this.filterStoreUnsubscribe()
  },
  async mounted() {
    this.filterStoreUnsubscribe = this.filterStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'refreshCharts') {
          this.getMongoChartData()
        }
      })
    })
    await this.getMongoChartData()
  },
  methods: {
    async getMongoChartData() {
      const body = {
        dateRange: this.filterStore.dateRange,
        accNumbers: this.filterStore.accNumbers,
        groupCode: this.filterStore.groupCode,
        mfr: this.filterStore.mfr,

        // chartType: 'stackedColumnChart',
      }

      this.loading = true
      const token = await this.$auth.getTokenSilently()
      this.moleculePerMonthTableData = await apiService.getData(token, 'moleculePerMonthTable', body)

      this.loading = false
    },
  },
}
</script>
